@import "../../styles/variable.module.scss";

.saleOffer {
  background: $themeColor;
  padding: 18px 0;

  .saledesc {
    @include medium_device {
      flex-direction: column;
      text-align: center;
      padding: 0 30px;
    }

    p {
      font-size: 16px;
      font-weight: 700;
      color: $lightColor;
      @include headingFont(font-family, "DM Sans", "Almarai");
      margin: 0 33px 0 0;

      @include medium_device {
        margin: 0 0 15px !important;
      }
    }

    .link {
      font-size: 18px;
      font-weight: 600;
      line-height: 1;
      color: $lightColor;

      @include medium_device {
        font-size: 18px;
      }

      svg {
        margin-left: 12px;
        transition: right 0.3s;
        position: relative;
        right: 0;

        @include medium_device {
          margin-left: 10px;
        }
      }

      &:hover {
        text-decoration: underline;

        svg {
          right: -3px;
        }
      }
    }

    .clodeIcon {
      position: absolute;
      right: 0;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      @include medium_device {
        top: -10px;
        right: -5px;
        svg {
          height: 16px;
          width: 15px;
        }
      }
    }
  }
}

.upyo_top_img {
  max-width: 544px;
  margin-right: 0;
  margin-left: auto;

  @media screen and (min-width: 576px) and (max-width: 991px) {
    max-width: 440px;
    margin: 32px auto 0;
  }

  @include medium_device {
    text-align: center;
    margin: 32px auto 0;
  }

  .sub_img {
    &>div {
      position: absolute;
      width: 37px;
      height: 47px;
    }

    .nft_1 {
      top: 28px;
      right: 136px;
      z-index: -1;
      -webkit-animation: nft_1 3s ease-in-out alternate-reverse infinite 0.1s;
      animation: nft_1 3s ease-in-out alternate-reverse infinite 0.1s;

      @keyframes nft_1 {
        0% {
          top: 28px;
        }

        50% {
          top: 20px;
        }

        100% {
          top: 28px;
        }
      }

      @-webkit-keyframes nft_1 {
        0% {
          top: 28px;
        }

        50% {
          top: 20px;
        }

        100% {
          top: 28px;
        }
      }

      @include extra_large {
        right: 108px;
      }
    }

    .nft_2 {
      top: 55px;
      left: 100px;
      z-index: 0;
      width: 40px;
      height: 35px;
      -webkit-animation: nft_2 3s ease-in-out alternate-reverse infinite 0.3s;
      animation: nft_2 3s ease-in-out alternate-reverse infinite 0.3s;

      @keyframes nft_2 {
        0% {
          top: 55px;
        }

        50% {
          top: 50px;
        }

        100% {
          top: 55px;
        }
      }

      @-webkit-keyframes nft_2 {
        0% {
          top: 55px;
        }

        50% {
          top: 50px;
        }

        100% {
          top: 55px;
        }
      }

      @include extra_large {
        left: 80px;

        @keyframes nft_2 {
          0% {
            top: 40px;
          }

          50% {
            top: 32px;
          }

          100% {
            top: 40px;
          }
        }
      }
    }

    .nft_3 {
      bottom: 124px;
      left: 80px;
      z-index: 0;
      width: 47px;
      height: 79px;
      -webkit-animation: nft_3 3s ease-in-out alternate-reverse infinite 0.6s;
      animation: nft_3 3s ease-in-out alternate-reverse infinite 0.6s;

      @keyframes nft_3 {
        0% {
          bottom: 104px;
        }

        50% {
          bottom: 96px;
        }

        100% {
          bottom: 104px;
        }
      }

      @-webkit-keyframes nft_3 {
        0% {
          bottom: 104px;
        }

        50% {
          bottom: 96px;
        }

        100% {
          bottom: 104px;
        }
      }

      @include extra_large {
        bottom: 80px;

        @keyframes nft_3 {
          0% {
            bottom: 80px;
          }

          50% {
            bottom: 72px;
          }

          100% {
            bottom: 80px;
          }
        }
      }
    }

    .nft_4 {
      bottom: 100px;
      right: 112px;
      z-index: 0;
      width: 43px;
      height: 95px;
      -webkit-animation: nft_4 3s ease-in-out alternate-reverse infinite 0.9s;
      animation: nft_4 3s ease-in-out alternate-reverse infinite 0.9s;

      @keyframes nft_4 {
        0% {
          bottom: 100px;
        }

        50% {
          bottom: 92px;
        }

        100% {
          bottom: 100px;
        }
      }

      @-webkit-keyframes nft_4 {
        0% {
          bottom: 100px;
        }

        50% {
          bottom: 92px;
        }

        100% {
          bottom: 100px;
        }
      }

      @include extra_large {
        bottom: 70px;
        right: 92px;

        @keyframes nft_4 {
          0% {
            bottom: 70px;
          }

          50% {
            bottom: 62px;
          }

          100% {
            bottom: 70px;
          }
        }
      }
    }
  }
}

.mainHead {
  @include medium_device {
    text-align: center;
  }

  .mainTitle {
    @include extra_large {
      font-size: 50px;
    }

    @include mobile_device {
      font-size: 32px;
      line-height: 40px;
      letter-spacing: -0.01em;
      margin-bottom: 20px;
    }
  }

  .desc {
    margin-bottom: 40px;

    @include medium_device {
      font-size: 14px;
      margin-bottom: 30px;
    }

    @include mobile_device {
      @include fontWeight(font-weight, 500, 700);
    }
  }

  a {
    @include mobile_device {
      min-width: 250px;
      margin: 0 auto;
    }

    &+a {
      margin-top: 20px;
    }
  }
}

.saleSlider {
  overflow: hidden;
  padding-top: 0;
  padding-bottom: 0;
  // margin-bottom: 64px;

  @include large_device {
    padding-left: 0;
    padding-right: 0;
  }

  @include medium_device {
    margin-bottom: 35px;
  }

  [class*=swiper] {
    padding: 0;
  }

  .arrows {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    min-height: 40px;
    z-index: 1;

    .row {
      min-height: 40px;
    }

    [class*=button-next] {
      // right: 15px;
      left: auto;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      bottom: auto;
      top: 50%;
      border-color: var(--border-color);
      width: 40px;
      height: 40px;
      min-height: 40px;
      min-width: 40px;
      max-height: 40px;
      max-width: 40px;

      // &::after{
      //     -webkit-filter: invert(100%) sepia(100%) saturate(38%) hue-rotate(254deg) brightness(150%) contrast(110%);
      //     filter: invert(100%) sepia(100%) saturate(38%) hue-rotate(254deg) brightness(150%) contrast(110%);
      // }
      @media screen and (min-width: 1200px) and (max-width: 1300px) {
        right: 5px;
      }

      @include extra_large {
        right: 0;
      }

      @include medium_device {
        right: 7px;
      }
    }

    [class*=button-prev] {
      // left: 15px;
      right: auto;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      bottom: auto;
      top: 50%;
      border-color: var(--border-color);
      width: 40px;
      height: 40px;

      // &::after{
      //     -webkit-filter: invert(100%) sepia(100%) saturate(38%) hue-rotate(254deg) brightness(150%) contrast(110%);
      //     filter: invert(100%) sepia(100%) saturate(38%) hue-rotate(254deg) brightness(150%) contrast(110%);
      // }
      @media screen and (min-width: 1200px) and (max-width: 1300px) {
        left: 5px;
      }

      @include extra_large {
        left: 0;
      }

      @include medium_device {
        left: 7px;
      }
    }
  }

  // [class*="swiper-slide"] {
  //   [class*="section"] {
  //     padding: 64px 0 0;
  //   }
  // }
  .topicOuter{
    > a > div{
      display: flex;
      > span{
        width: 100% !important;
      }
    }
  }
}

[dir="rtl"] {
  .mainHead {
    .mainTitle {
      line-height: 75px;

      @include mobile_device {
        line-height: 55px;
      }
    }
  }
  .saleOffer {
      .saledesc {
          p{
              margin: 0 0 0 33px;
          }
          .link{
              svg{
                  margin-left: 0;
                  margin-right: 12px;
                  transform: rotate(180deg);
                  @include medium_device{
                      margin-right: 10px;
                  }
              }
              &:hover{
                  text-decoration: underline;
                  svg{
                      right: 3px;
                  }
              }
          }
          .clodeIcon{
              left: 0;
              right: auto;
              @include medium_device{
                  left: -5px;
              }
          }
      }
    }

  .saleSlider {
    .arrows {
      [class*=button-next] {
        left: -60px;
        right: auto;

        @media screen and (min-width: 1200px) and (max-width: 1300px) {
          left: 5px;
        }

        @include extra_large {
          left: 0;
        }

        @include medium_device {
          left: 7px;
        }
      }

      [class*=button-prev] {
        left: auto;
        right: -60px;

        @media screen and (min-width: 1200px) and (max-width: 1300px) {
          right: 5px;
        }

        @include extra_large {
          right: 0;
        }

        @include medium_device {
          right: 7px;
        }
      }
    }
  }
}

[data-theme="dark"] {
  .upyo_top_img {
    .dark {
      display: block !important;
    }
  }
  .saleSlider{
    .topicOuter{
      > a > [class~="dark"]{
        display: flex !important;
      }
    }
  }
}